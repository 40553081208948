'use client';
import { usePostCartsAddHook } from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { QueryKeys } from '@hooks/queries/queryKeys';
import { useCart } from '@hooks/queries/use-query-cart';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useMemo } from 'react';

import { Product } from '@/shared/types/common';
import { Button } from '@/shared/ui/button';
import { Illustration } from '@/shared/ui/illustration';
import { useStore } from '@/store/context';

import styles from './add-to-cart.module.scss';

export type AddToCartFeatureProps = {
  item: Product;
};

export const AddToCartFeatureMobile = observer(
  ({ item }: AddToCartFeatureProps) => {
    const queryClient = useQueryClient();
    const addItem = usePostCartsAddHook();
    const { items: cartItems } = useCart();

    const cartItem = useMemo(
      () =>
        cartItems.find(
          currentItem => currentItem.id.toString() === item.id.toString(),
        ),
      [item, cartItems],
    );

    const addItemRequest = useMemo(
      () => async () => {
        const response = await addItem({ id: item.id, count: 1 });
        queryClient.setQueryData([QueryKeys.CART], response);
      },
      [item],
    );

    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      e?.stopPropagation();
      addItemRequest();
    };

    if (cartItem) {
      return (
        <AdjustItemQuantityFeature
          id={item.id}
          price={item.price!}
          prices={item.prices!}
          selected={cartItem.selected}
          initialCount={1}
          minQuantity={0}
          realName={''}
          className={styles['quantity-container']}
        />
      );
    }

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.95 }}
        iconLeft={
          <Illustration
            id={'btn-icon'}
            name={'cart-plus'}
            spriteName={'icons'}
          />
        }
        width={'full-w'}
        className={cn(styles['action-btn'])}
        onClick={handleActionButtonClick}
        text={'Add to cart'}
      />
    );
  },
);
