'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { CardSameItemEntityMobile } from '@entities/mobile/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { AddToCartShoppingItemFeatureMobile } from '@features/mobile/add-to-cart';
import { ProductsQuery } from '@hooks/queries/common.types';
import { useProducts } from '@hooks/queries/use-query-products';
import {
  getSimilarInPriceQueryFilters,
  SIMILAR_IN_PRICE_QUERY_KEY,
} from '@widgets/desktop/list-cards-wrappers/similar-in-price-items';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useStep } from 'usehooks-ts';

import { Product } from '@/shared/types/common';
import {
  Divider,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
  Skeleton,
  TagQuantity,
  Typography,
} from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileDefaultIcon,
} from '@/shared/ui/mobile/additional-info';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './cart-item-no-longer-available-modal.module.scss';

export const getProductFilters = () => {
  const query: ProductsQuery = {
    filter: {
      search: '',
      types: [
        {
          type: 'pet',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          levels: ['default', 'mega_neon', 'neon'],
          ages: [
            'newborn',
            'junior',
            'pre_teen',
            'teen',
            'post_teen',
            'reborn',
            'full_grown',
            'twinkle',
            'sparkle',
            'flare',
            'sunshine',
            'luminous',
          ],
        },
        {
          type: 'egg',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'potion',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
        },
        {
          type: 'transport',
          rarity: ['uncommon', 'legendary', 'ultra_rare', 'rare', 'common'],
          subType: [
            'scooters',
            'skateboards',
            'legendary_gift_vehicles',
            'snowboards',
            'event_vehicles',
            'vehicle_dealership',
            'premium_vehicles',
            'star_rewards_vehicles',
          ],
        },
      ],
      onlyHotSales: true,
      onlyNew: false,
      onlyExclusive: false,
      onlyDiscount: false,
    },
    order: {
      by: 'price',
      direction: 'asc',
    },
    page: 0,
    limit: 20,
  };

  return query;
};

export const CartItemNoLongerAvailableModal = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const cartStore = useStore().cart;
    const [step, { goToNextStep, reset }] = useStep(2);
    const handleClose = () => {
      reset();
      cartStore.setNotAvailable(null);
    };

    return (
      <ModalWrapper
        hideBottomSheet={step === 2}
        className={step === 1 ? styles.modal : styles['modal--add-item']}
        close={handleClose}
        isMobile={isMobile}
        isOpened={Boolean(cartStore.notAvailableName)}
      >
        <>
          {step === 1 && (
            <WarningContent
              openAddItemModal={goToNextStep}
              handleClose={handleClose}
              isMobile={isMobile}
            />
          )}

          {step === 2 && (
            <AddItem
              isMobile={isMobile}
              realName={cartStore.notAvailableName!}
              handleClose={handleClose}
            />
          )}
        </>
      </ModalWrapper>
    );
  },
);

const WarningContent = ({
  isMobile,
  handleClose,
  openAddItemModal,
}: {
  isMobile?: boolean;
  handleClose: () => void;
  openAddItemModal: () => void;
}) => {
  return (
    <ModalContent
      className={styles['container']}
      topRightSlot={<ModalContent.CloseButton onClick={handleClose} />}
    >
      <IllustrationModal variant={'purchase-is-over'} />
      <ModalContent.Title
        title={'Oops!'}
        subtitle={'This item is no longer available at this price'}
      />

      <ModalContent.Body>
        {!isMobile ? (
          <AdditionalInfo
            align={'center'}
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfo.DefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            You can choose a similar item at a different price
          </AdditionalInfo>
        ) : (
          <AdditionalInfoMobile
            width={'full-w'}
            variant={'success'}
            icon={
              <AdditionalInfoMobileDefaultIcon
                variant={'success'}
                name={'info-square'}
              />
            }
          >
            You can choose a similar item at a different price
          </AdditionalInfoMobile>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Add item'} onClick={openAddItemModal} />
        <ModalContent.Button
          variant={'secondary'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
};

const AddItem = ({
  isMobile,
  handleClose,
  realName,
}: {
  isMobile?: boolean;
  handleClose: () => void;
  realName: string;
}) => {
  const { isLoading, items, isFetching } = useProducts({
    getQueryFiltersFn: getProductFilters,
    uniqueQueryKey: SIMILAR_IN_PRICE_QUERY_KEY,
  });

  const itemByName = items.find(item => item.realName === realName);

  const renderOfferItem = (item: Product, index: number) => (
    <React.Fragment key={`cart-offer-${item?.id}`}>
      <CardItemOfferEntity
        className={styles['item']}
        imageUri={itemByName.img}
        game={'adopt'}
        flyable={item.flyable}
        rideable={item.rideable}
        age={item.age}
        type={itemByName.type}
        rare={itemByName.rare}
        price={item.price}
        bottomSlot={<AddToCartOfferFeature item={item} />}
      />
      {index !== items.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return isMobile ? (
    <BottomSheetUi
      className={styles['sheet']}
      isOpen={true}
      handleClose={handleClose}
    >
      <BottomSheetUi.Content className={styles['sheet-content']}>
        <div className={styles['items__sheet-header-container']}>
          <BottomSheetUi.Header onClick={() => {}}>
            Add item
          </BottomSheetUi.Header>
          <TagQuantity quantity={itemByName?.products.length} />
        </div>
        <div className={styles['items__sheet-container']}>
          {!itemByName?.products.length && !isLoading && !isFetching && (
            <div className={styles['not-found-container']}>
              <IllustrationModal variant={'same-items-not-found'} />
              <div className={styles['not-found-text-mobile']}>
                <Typography className={styles['title']}>
                  No similar items found
                </Typography>
                <Typography className={styles['subtitle']}>
                  Try selecting a different item or changing filter settings
                </Typography>
              </div>
            </div>
          )}
          {isLoading || isFetching
            ? Array(10)
                .fill('')
                .map((_, idx) => (
                  <Skeleton
                    key={`skeleton-list-cards-${idx}`}
                    className={styles['skeleton']}
                  />
                ))
            : itemByName?.products.length > 0 &&
              itemByName.products.map((product: Product, idx: number) => {
                return (
                  <CardSameItemEntityMobile
                    key={`${product.id}-${idx}-offer-mobile`}
                    leftSlot={
                      <CardSameItemEntityMobile.Item
                        img={itemByName.img}
                        rare={itemByName.rare}
                        game={'adopt'}
                      />
                    }
                    middleSlot={
                      <CardSameItemEntityMobile.ItemInfo
                        price={{
                          current: product.price ?? 0,
                          old: product.price ?? 0,
                        }}
                        title={
                          itemByName.type === 'pet'
                            ? itemByName.type
                            : itemByName.age!
                        }
                        rightSlot={
                          <CardSameItemEntityMobile.ItemProperties
                            flyable={product.flyable}
                            rideable={product.rideable}
                          />
                        }
                      />
                    }
                    rightSlot={
                      <AddToCartShoppingItemFeatureMobile
                        className={styles['sheet__shopping-card-add-to-cart']}
                        item={product}
                      />
                    }
                  />
                );
              })}
        </div>
        <BottomSheetUi.Button text={'Close'} onClick={handleClose} />
      </BottomSheetUi.Content>
    </BottomSheetUi>
  ) : (
    <ModalContent className={styles['container--add-item']}>
      <ModalContent.Header onClick={() => {}}>
        <ModalContent.HeaderLabel
          className={styles['label--icon']}
          icon={'notes-minimalistic'}
          variant={'primary'}
        >
          Add item
        </ModalContent.HeaderLabel>

        <ModalContent.HeaderText>
          {itemByName?.products.length} pcs
        </ModalContent.HeaderText>
      </ModalContent.Header>

      <ModalContent.Body className={styles['items-list']}>
        {!itemByName && !isFetching && !isLoading && (
          <div className={styles['not-found']}>
            <IllustrationModal variant={'same-items-not-found'} />
            <div className={styles['not-found-text']}>
              <Typography className={styles['title']}>
                No similar items found
              </Typography>
              <Typography className={styles['subtitle']}>
                Try selecting a different item or changing filter settings
              </Typography>
            </div>
          </div>
        )}
        {isLoading || isFetching
          ? Array(10)
              .fill('')
              .map((_, idx) => (
                <Skeleton
                  key={`skeleton-list-cards-${idx}`}
                  className={styles['skeleton']}
                />
              ))
          : itemByName?.products.length && (
              <ul className={styles['items']}>
                {itemByName.products.map(renderOfferItem)}
              </ul>
            )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Close'} onClick={handleClose} />
      </ModalContent.Footer>
    </ModalContent>
  );
};
