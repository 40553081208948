'use client';

import { useCart } from '@hooks/queries/use-query-cart';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Illustration, Typography } from '@/shared/ui';
import { Button } from '@/shared/ui/button';
import Checkbox from '@/shared/ui/desktop/check-box/check-box';

import styles from './cart-select-all.module.scss';

export const CartSelectAllFeature = observer(
  ({ allSelected, isMobile }: { allSelected: boolean; isMobile?: boolean }) => {
    const { toggleAllCartSelected } = useCart();

    if (isMobile)
      return (
        <div className={styles['select-mobile']}>
          <Typography className={styles['text-mobile']}>Select all</Typography>
          <Checkbox
            checked={allSelected}
            onChange={() => toggleAllCartSelected(allSelected)}
          />
        </div>
      );

    return (
      <Button
        width={'content'}
        className={styles['select-btn']}
        iconRight={
          <Illustration
            name={allSelected ? 'close-square' : 'check-square'}
            spriteName={'icons'}
          />
        }
        variant={'secondary'}
        onClick={() => toggleAllCartSelected(allSelected)}
        text={allSelected ? 'Deselect all' : 'Select all'}
      />
    );
  },
);
