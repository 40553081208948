'use client';
import { CartReplaceItemModal } from '@modals';
import cn from 'clsx';
import React, { useState } from 'react';

import { Button, Illustration } from '@/shared/ui';

import styles from './cart-replace-item.module.scss';

type CartReplaceItemProps = {
  className?: string;
  isMobile?: boolean;
};

export const CartReplaceItemFeature = ({
  className,
  isMobile,
}: CartReplaceItemProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    typeof window !== 'undefined' && (
      <>
        <Button
          className={cn(styles['replace'], className)}
          iconLeft={
            <Illustration
              name={'cart-plus'}
              spriteName={'icons'}
              spanTagClassName={styles['icon']}
            />
          }
          text={'Replace'}
          onClick={() => setIsOpened(true)}
          variant={'primary'}
        />
        {isOpened && (
          <CartReplaceItemModal
            isMobile={isMobile}
            isOpened={isOpened}
            handleClose={() => setIsOpened(false)}
          />
        )}
      </>
    )
  );
};
