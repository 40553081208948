'use client';
import { useQueryString } from '@hooks/client';
import { useFavorite } from '@hooks/queries/use-query-favorite';
import { observer } from 'mobx-react-lite';
import { useIsClient } from 'usehooks-ts';

import { FavoriteData } from '@/shared/types/common';
import { ButtonFavoriteMobile } from '@/shared/ui/mobile/button-favorite/button-favorite';
export type AddToFavoriteFeatureProps = {
  variant: 'primary' | 'secondary';
  realName: string;
};
export const AddToFavoriteFeatureMobile = observer(
  ({ variant, realName }: AddToFavoriteFeatureProps) => {
    const { mappedParams } = useQueryString();
    const { items, addToFavorite, deleteItem } = useFavorite(mappedParams);
    const likedItem = items.find(item => item.realName === realName);
    const isClient = useIsClient();

    if (!isClient) {
      return null;
    }

    return (
      <ButtonFavoriteMobile
        variant={variant}
        onPress={() =>
          !likedItem ? addToFavorite(realName) : deleteItem(realName)
        }
        isLiked={Boolean(likedItem)}
      />
    );
  },
);
