import cn from 'clsx';
import { ReactNode } from 'react';

import { DivElementType } from '@/shared/types/common';
import { Typography } from '@/shared/ui';

import styles from './total-row.module.scss';

export type CartTotalRowProps = DivElementType & {
  title: string;
  subtitle?: string | ReactNode;
  rightSlot?: ReactNode;
  isLink?: boolean;
  onClick?: () => void;
};

export const CartTotalRow = ({
  title,
  subtitle,
  isLink,
  onClick,
  rightSlot,
}: CartTotalRowProps) => {
  const capitalize = (string: string) =>
    string[0].toUpperCase() + string.slice(1);

  const titleClasses = cn(styles['total__row-title'], {
    [styles['total__row-title-link']]: isLink,
  });

  return (
    <div className={styles['total__row']} onClick={onClick}>
      <div className={styles['total__row-right']}>
        <div className={styles['violet-circle']} />
        <div className={styles['total__row-text']}>
          <Typography className={titleClasses}>{capitalize(title)}</Typography>
          {subtitle && (
            <Typography className={styles['total__row-subtitle']}>
              {subtitle}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles['total-row__left']}>{rightSlot}</div>
    </div>
  );
};
