'use client';
import { useCart } from '@hooks/queries/use-query-cart';
import { CartDeleteItemModal } from '@modals';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './clear-selected.module.scss';

export const CartClearSelectedFeature = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const { items: cartItems, removeSelected } = useCart();

    const hasSelected = cartItems.some(item => item.selected);

    const handleDelete = async () => {
      await removeSelected();
      setModalOpened(false);
    };

    const classes = cn(styles['clear-btn'], { [styles['mobile']]: isMobile });

    if (!hasSelected) return null;

    return (
      <>
        <Button
          width={'content'}
          className={classes}
          iconLeft={
            <Illustration
              name={'red-trash-bin-minimalistic'}
              spriteName={'icons'}
            />
          }
          variant={'tertiary'}
          onClick={() => setModalOpened(true)}
          text={isMobile ? '' : 'Delete selected'}
        />
        <CartDeleteItemModal
          isMobile={isMobile}
          onDelete={handleDelete}
          isOpened={modalOpened}
          handleClose={() => setModalOpened(false)}
        />
      </>
    );
  },
);
