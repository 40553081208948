'use client';
import { CardShoppingItemEntityMobile } from '@entities/mobile/cards';
import { AdjustItemQuantityFeature } from '@features/adjust-item-quantity';
import { CartClearSoldOutFeature } from '@features/cart-clear-soldout';
import { CartReplaceItemFeature } from '@features/cart-replace-item';
import { AddToFavoriteShoppingItemFeatureMobile } from '@features/mobile/add-to-favorite';
import { CartConfirmItemDeleteMobile } from '@features/mobile/remove/ui';
import { useCartItemsInfo } from '@hooks/queries/use-cart-items-info';
import { useCart } from '@hooks/queries/use-query-cart';
import { CartDeleteItemModal, CartPurchaseIsOverModal } from '@modals';
import { getAvailableCartItems, getUnavailableCartItems } from '@utils/cart';
import { TopUpSkeleton } from '@widgets/desktop/cart/ui/topup-cashout-method/skeleton/skeleton';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { Divider, Illustration, TagQuantity, Typography } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './items.module.scss';

export const CartItemsMobile = observer(() => {
  const notificationStore = useStore()?.notifications;
  const [isConfirmModalOpened, setIsOpened] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<{
    id: number;
    soldOut: boolean;
  } | null>(null);
  const {
    items: cartItems,
    summary,
    isLoading: cartLoading,
    deleteAvailableItem,
    deleteUnavailableItem,
  } = useCart();

  const ids = cartItems.map(item => item.id);

  const { items: itemsInfo, isLoading: infoLoading } = useCartItemsInfo(
    ids || [],
  );

  const itemsToRender = cartItems.map(item => {
    const itemInfo = itemsInfo?.find(info => info.id == item.id);
    if (!itemInfo) return;

    return { ...item, ...itemInfo };
  });

  const availableItems = useMemo(
    () => getAvailableCartItems(itemsToRender),
    [itemsToRender],
  );

  const availableCount = availableItems?.reduce(
    (acc, curr) => curr.count + acc,
    0,
  );

  const unavailableItems = useMemo(
    () => getUnavailableCartItems(itemsToRender),
    [itemsToRender],
  );

  useEffect(() => {
    if (summary && summary?.count > 10) {
      notificationStore?.addNotification({
        variant: 'warn',
        title: 'Limit!',
        subtitle: 'You can only purchase up to 10 items at a time.',
        delay: 3,
        id: 'limit-10',
      });
    }
  }, [summary]);

  const handleOpenConfirmDeleteModal = (id: number, soldOut: boolean) => {
    setIsOpened(true);
    setItemToDelete({ id, soldOut });
  };

  const confirmItemDelete = async () => {
    if (itemToDelete) {
      if (itemToDelete.soldOut) {
        deleteUnavailableItem(itemToDelete.id);
      } else {
        deleteAvailableItem(itemToDelete.id);
      }
      setIsOpened(false);
    }
  };

  return (
    <div className={styles['cart-mobile__items']}>
      {typeof window !== 'undefined' && window?.showCartPurchaseIsOverModal && (
        <CartPurchaseIsOverModal isMobile />
      )}
      <CartDeleteItemModal
        isMobile
        onDelete={confirmItemDelete}
        isOpened={isConfirmModalOpened}
        handleClose={() => setIsOpened(false)}
      />
      {cartLoading || infoLoading ? (
        <TopUpSkeleton />
      ) : (
        availableItems.map((cartItem, idx) => {
          if (!cartItem) return null;

          return (
            <React.Fragment key={`mobile-cart-item-${idx}-${cartItem.id}`}>
              <CardShoppingItemEntityMobile
                size={'l'}
                item={cartItem}
                showPrice
                bottomLeft={
                  <AdjustItemQuantityFeature
                    className={styles['stepper']}
                    id={cartItem.id}
                    prices={cartItem.prices!}
                    price={cartItem.price!}
                    selected={cartItem.selected}
                    initialCount={cartItem.count}
                    maxQuantity={10}
                    realName={cartItem.realName}
                  />
                }
                bottomRight={
                  <>
                    <AddToFavoriteShoppingItemFeatureMobile
                      realName={cartItem.realName}
                    />
                    <CartConfirmItemDeleteMobile
                      onClick={() =>
                        handleOpenConfirmDeleteModal(cartItem?.id, false)
                      }
                    />
                  </>
                }
              />
              {availableItems?.length - 1 !== idx && (
                <Divider direction={'horizontal'} />
              )}
            </React.Fragment>
          );
        })
      )}
      {!cartLoading && !infoLoading && unavailableItems.length > 0 ? (
        <>
          <div className={styles['clear']}>
            <div className={styles['info']}>
              <Illustration
                style={{ color: '#EB3C3C' }}
                name={'tag'}
                spriteName={'icons'}
                size={34}
              />
              <div className={styles['counter']}>
                <Typography className={styles['title']}>Sold out</Typography>
                <TagQuantity quantity={unavailableItems.length} />
              </div>
            </div>
            <CartClearSoldOutFeature isMobile />
          </div>
          {unavailableItems.map((cartItem, idx) => {
            if (!cartItem) return null;

            return (
              <React.Fragment key={`mobile-cart-item-${idx}-${cartItem.id}`}>
                <CardShoppingItemEntityMobile
                  size={'l'}
                  showPrice={false}
                  item={cartItem}
                  isSoldout
                  bottomLeft={<CartReplaceItemFeature isMobile />}
                  bottomRight={
                    <>
                      <AddToFavoriteShoppingItemFeatureMobile
                        realName={cartItem.realName}
                      />
                      <CartConfirmItemDeleteMobile
                        onClick={() =>
                          handleOpenConfirmDeleteModal(cartItem?.id, false)
                        }
                      />
                    </>
                  }
                />
                {unavailableItems?.length - 1 !== idx && (
                  <Divider direction={'horizontal'} />
                )}
              </React.Fragment>
            );
          })}
        </>
      ) : null}
    </div>
  );
});
