'use client';
import { CardOrder } from '@entities/desktop/cards';
import { CheckoutInfoType } from '@entities/desktop/cards/card-order/ui/card-order.types';
import React from 'react';

import { WithCurrency } from '@/shared/hocs';
import {
  Divider,
  Illustration,
  ModalContent,
  ModalWrapper,
  Typography,
} from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './cart-order-details.module.scss';
export const CartOrderDetailsModal = ({
  handleClose,
  isOpened,
  checkoutInfo,
  totalAmount,
  promocodeInfo,
}: {
  isOpened: boolean;
  handleClose: () => void;
  checkoutInfo: CheckoutInfoType[];
  totalAmount: string | number;
  promocodeInfo: {
    isPromocodeApplied: boolean;
    promocodePercentValue: string | number;
  };
}) => {
  return (
    <ModalWrapper
      className={styles.modal}
      close={handleClose}
      isOpened={isOpened}
    >
      <CartOrderDetailsModal.Content
        checkoutInfo={checkoutInfo}
        totalAmount={totalAmount}
        handleClose={handleClose}
        promocodeInfo={promocodeInfo}
      />
    </ModalWrapper>
  );
};

CartOrderDetailsModal.Content = ({
  handleClose,
  checkoutInfo,
  promocodeInfo,
  totalAmount,
}: {
  handleClose: () => void;
  checkoutInfo: CheckoutInfoType[];
  totalAmount: string | number;
  promocodeInfo: {
    isPromocodeApplied: boolean;
    promocodePercentValue: string | number;
  };
}) => {
  return (
    <ModalContent>
      <ModalContent.Header>
        <div className={styles['header-left']}>
          <ModalContent.HeaderLabel
            className={styles['icon']}
            icon={'wallet'}
            variant={'primary'}
          >
            More details
          </ModalContent.HeaderLabel>
        </div>
        <ModalContent.CloseButton onClick={handleClose} />
      </ModalContent.Header>

      <ModalContent.Body className={styles['content']}>
        <div className={styles['content-items']}>
          {checkoutInfo.map((info, idx) => {
            return <CardOrder key={`${info.title}-${idx}`} info={info} />;
          })}
          {promocodeInfo.isPromocodeApplied && (
            <CardOrder
              info={{
                title: 'Promocode',
                value:
                  (Number(totalAmount) / 100) *
                  Number(promocodeInfo.promocodePercentValue),
                type: 'promocode',
              }}
            />
          )}
        </div>
        <Divider direction="horizontal" />
        <CartOrderDetailsModal.TotalSum value={totalAmount} />
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button
          variant={'primary'}
          text={'Close'}
          onClick={handleClose}
        />
      </ModalContent.Footer>
    </ModalContent>
  );
};

CartOrderDetailsModal.TotalSum = ({ value }: { value: string | number }) => {
  return (
    <div className={styles['total']}>
      <div className={styles['total-info']}>
        <Illustration
          style={{ color: '#6941C6' }}
          name={'bill-list'}
          spriteName={'icons'}
          size={24}
        />

        <Typography weight={'medium'} className={styles['amount-text']}>
          Total sum:
        </Typography>
      </div>
      {Number(value) >= 0 ? (
        <Typography weight={'semi-bold'} className={styles['amount']}>
          <WithCurrency>{Number(value).toFixed(2)}</WithCurrency>
        </Typography>
      ) : (
        <Typography weight={'semi-bold'} className={styles['amount']}>
          <WithCurrency>0</WithCurrency>
        </Typography>
      )}
    </div>
  );
};
