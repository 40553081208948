import React from 'react';

import { Divider, Skeleton } from '@/shared/ui';

import styles from './skeleton.module.scss';

export const TopUpSkeleton = () => {
  return (
    <div className={styles['items']}>
      {Array(10)
        .fill('')
        .map((_, idx) => {
          return (
            <>
              <div key={`cart-shop-${idx}`} className={styles['item']}>
                <div className={styles['left-slot']}>
                  <div className={styles['info']}>
                    <Skeleton className={styles['checkbox']} />
                    <Skeleton className={styles['image']} />
                    <div className={styles['title-container']}>
                      <Skeleton className={styles['subtitle']} />
                      <Skeleton className={styles['title']} />
                    </div>
                  </div>
                  <div className={styles['buttons']}>
                    <Skeleton className={styles['button']} />
                    <Skeleton className={styles['button']} />
                  </div>
                </div>
                <Divider className={styles['divider']} direction={'vertical'} />
                <div className={styles['right-slot']}>
                  <Skeleton className={styles['price']} />
                </div>
              </div>
              {_.length - 1 !== idx && <Divider direction={'horizontal'} />}
            </>
          );
        })}
    </div>
  );
};
