'use client';

import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/navigation';
import React from 'react';

import {
  Illustration,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
} from '@/shared/ui';
import { AdditionalInfo } from '@/shared/ui/desktop/additional-info';
import {
  AdditionalInfoMobile,
  AdditionalInfoMobileDefaultIcon,
} from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './cart-successful-payment.module.scss';

type CartSuccessfulPaymentProps = {
  isMobile?: boolean;
};

export const CartSuccessfulPaymentModal = observer(
  ({ isMobile }: CartSuccessfulPaymentProps) => {
    const router = useRouter();
    const cartStore = useStore().cart;
    const handleClose = () => {
      cartStore.setSuccess(null);
    };

    return (
      <ModalWrapper
        className={styles['modal']}
        close={handleClose}
        isMobile={isMobile}
        isOpened={Boolean(cartStore.success)}
      >
        <ModalContent
          className={styles['container']}
          topRightSlot={<ModalContent.CloseButton onClick={handleClose} />}
        >
          <IllustrationModal variant={'success'} />
          <ModalContent.Title
            title={'Payment Successful!'}
            subtitle={'Items you bought are in your inventory'}
          />

          <ModalContent.Body>
            {!isMobile ? (
              <AdditionalInfo
                align={'center'}
                width={'full-w'}
                variant={'success'}
                icon={
                  <AdditionalInfo.DefaultIcon
                    variant={'success'}
                    name={'info-square'}
                  />
                }
              >
                Withdraw them before the time limit expires!
              </AdditionalInfo>
            ) : (
              <AdditionalInfoMobile
                width={'full-w'}
                variant={'success'}
                icon={
                  <AdditionalInfoMobileDefaultIcon
                    variant={'success'}
                    name={'info-square'}
                  />
                }
              >
                You can choose a similar item at a different price
              </AdditionalInfoMobile>
            )}
          </ModalContent.Body>
          <ModalContent.Footer>
            <ModalContent.Button
              className={styles['btn']}
              text={'Inventory'}
              iconLeft={<Illustration spriteName={'icons'} name={'bag'} />}
              onClick={() => router.push('/inventory')}
            />
            <ModalContent.Button
              variant={'secondary'}
              text={'Close'}
              onClick={handleClose}
            />
          </ModalContent.Footer>
        </ModalContent>
      </ModalWrapper>
    );
  },
);
