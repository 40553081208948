'use client';
import { CheckoutInfoType } from '@entities/desktop/cards/card-order/ui/card-order.types';
import { useCartItemsInfo } from '@hooks/queries/use-cart-items-info';
import { useCart } from '@hooks/queries/use-query-cart';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';

import { ProductById } from '@/shared/types/common';
import { Divider, TagCategory, Typography } from '@/shared/ui';
import { Switch } from '@/shared/ui/desktop/switch';
import { HeightAnimatedWrapper } from '@/shared/ui/height-animated-wrapper/height-animated-wrapper';
import { useStore } from '@/store/context';

import { CartPromocode } from './promocode';
import styles from './purchase-checkout.module.scss';
import { CartTotalRow } from './total-row';

export const CartPurchaseCheckoutMobile = observer(() => {
  const cart = useStore()?.cart;
  const [detailsOpened, setDetailsOpened] = useState(false);

  const { items: cartItems, summary, isLoading: cartLoading } = useCart();
  const { items: itemsInfo, isLoading: infoLoading } = useCartItemsInfo(
    cartItems.map(item => item.id),
  );

  const itemsToRender = cartItems.map(item => {
    const itemInfo = itemsInfo?.find(info => info.id === item.id);
    if (!itemInfo) return;

    return { ...item, ...itemInfo };
  });

  const itemsForPurchase = useMemo(() => {
    return itemsToRender?.reduce((accumulator: ProductById[], item) => {
      if (!item || !item.selected) return accumulator;
      const min = Math.min(item?.prices?.length ?? 0, item?.count ?? 0);
      const newPrices =
        item?.prices?.slice(0, min).filter((price: number) => price !== null) ||
        [];

      if (min > 0) {
        accumulator.push({ ...item, prices: newPrices, count: min });
      }
      return accumulator;
    }, []);
  }, [itemsToRender]);

  const checkoutInfo: CheckoutInfoType[] = itemsForPurchase?.map(item => {
    return {
      title: item!.name,
      type: 'item',
      item: item,
      oldValue: item.prices
        ? item.prices.reduce((acc, curr) => acc + Number(curr), 0)
        : 0,
      value: item.prices
        ? item.prices.reduce((acc, curr) => acc + Number(curr), 0)
        : 0,
      flyable: Boolean(item?.flyable),
      rideable: Boolean(item?.rideable),
    };
  });

  const isUsingAccountBalance = false;
  const balanceAmount = 0;
  const totalAmount = summary?.totalPrice ?? 0;
  const promocodeInfo = {
    isPromocodeApplied: false,
    promocodePercentValue: 0,
  };
  const isPromocodeApplied = !!cart?.checkoutInfo?.promocode;
  const currencySign = useStore().app.getCurrencySign;

  return (
    <div className={styles['cart-mobile__purchase-checkout']}>
      <div className={styles['partial-payment']}>
        <Typography className={styles['partial-payment__text']}>
          Pay part of the amount from my balance:
        </Typography>
        <Switch
          variant=""
          disabled={!isUsingAccountBalance}
          isChecked={isUsingAccountBalance}
          setIsChecked={() => {}}
        />
      </div>
      <Divider direction={'horizontal'} />

      <div className={styles['total']}>
        <HeightAnimatedWrapper className={styles['items-container']}>
          {detailsOpened &&
            Array.isArray(checkoutInfo) &&
            checkoutInfo.map((item, idx) => (
              <CartTotalRow
                key={`${idx}${item.title}`}
                title={item.title}
                subtitle={
                  <div className={styles['properties']}>
                    {item.item?.pumping && (
                      <TagCategory variant={item.item?.pumping} />
                    )}
                    {item.flyable && <TagCategory variant={'fly'} />}
                    {item.rideable && <TagCategory variant={'ride'} />}
                  </div>
                }
                rightSlot={
                  <div className={styles['value-container']}>
                    <Typography
                      className={cn(styles['value'], styles['old-value'])}
                    >
                      {!!item.oldValue &&
                        `${currencySign} ${item.oldValue.toFixed(2)}`}
                    </Typography>
                    <Typography
                      className={cn(
                        styles['value'],
                        styles[
                          `total__row-price-${
                            !item.oldValue
                              ? 'neutral'
                              : item.value > item.oldValue
                                ? 'neg'
                                : 'pos'
                          }`
                        ],
                      )}
                      weight={'semi-bold'}
                    >
                      + {currencySign} {item.value.toFixed(2)}
                    </Typography>
                  </div>
                }
              />
            ))}
        </HeightAnimatedWrapper>

        <CartTotalRow
          title={'Commission'}
          subtitle={'3%'}
          rightSlot={
            <Typography
              className={cn(
                styles['total__row-price'],
                styles['total__row-price-neutral'],
              )}
            >
              + {currencySign} 0.56
            </Typography>
          }
        />

        {isPromocodeApplied && (
          <CartTotalRow
            title={'Promocode'}
            subtitle={'-15%'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - {currencySign} 2.89
              </Typography>
            }
          />
        )}

        {isUsingAccountBalance && (
          <CartTotalRow
            title={'My balance'}
            rightSlot={
              <Typography
                className={cn(
                  styles['total__row-price'],
                  styles['total__row-price-pos'],
                )}
              >
                - $ {balanceAmount}
              </Typography>
            }
          />
        )}

        <CartTotalRow
          isLink
          onClick={() => setDetailsOpened(!detailsOpened)}
          title={detailsOpened ? 'Less details' : 'More details'}
        />
      </div>

      <Divider direction={'horizontal'} />

      <CartTotalRow
        title={'Total sum:'}
        rightSlot={
          <Typography
            className={cn(
              styles['total__row-price'],
              styles['total__row-price-sum'],
            )}
          >
            {currencySign}{' '}
            {Number(totalAmount) >= 0 ? Number(totalAmount).toFixed(2) : '0'}
          </Typography>
        }
      />

      <CartPromocode />
    </div>
  );
});
