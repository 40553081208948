'use client';
import { usePostProductsPacks } from '@api-product/🧸-товары/🧸-товары';
import { useQuery } from '@tanstack/react-query';

import { QueryKeys } from './queryKeys';

export const useCartItemsInfo = (ids: number[]) => {
  const { mutateAsync } = usePostProductsPacks();

  const {
    data: responseData,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey: [QueryKeys.CART_ITEMS_INFO, ids],
    queryFn: () => {
      return mutateAsync({
        data: { ids },
      });
    },
    refetchInterval: 60000 * 5,
    refetchOnWindowFocus: false,
    enabled: Boolean(ids.length),
    placeholderData: prev => prev,
    gcTime: 0,
  });

  const items = responseData?.data;

  return {
    items,
    isLoading: isLoading,
    isFetching,
  };
};
