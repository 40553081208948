'use client';
import { CartClearSelectedFeature } from '@features/cart-clear-selected/ui';
import { CartSelectAllFeature } from '@features/cart-select-all';
import { useCart } from '@hooks/queries/use-query-cart';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { TagQuantity, Typography } from '@/shared/ui';
import { Illustration } from '@/shared/ui';
import { AdditionalInfoMobile } from '@/shared/ui/mobile/additional-info';
import { useStore } from '@/store/context';

import styles from './header.module.scss';

export const CartHeaderMobile = observer(() => {
  const { items, isLoading, allSelected } = useCart();

  const isCartEmpty = !isLoading && !items.length;

  const totalQuantity = items.reduce(
    (acc, cur) => Math.min(cur.count, cur.prices.length) + acc,
    0,
  );

  return (
    <div className={styles['cart-mobile__header']}>
      <div
        className={cn(styles['cart-mobile__header-top'], {
          [styles['no-items']]: isCartEmpty,
        })}
      >
        <div className={styles['title-block']}>
          <Typography className={styles['title']}>Cart</Typography>
          <TagQuantity quantity={totalQuantity} />
        </div>
        <div className={styles['buttons']}>
          <CartClearSelectedFeature isMobile />
          <CartSelectAllFeature allSelected={!!allSelected} isMobile />
        </div>
      </div>
      {!isCartEmpty && (
        <div className={styles['cart-mobile__header-bottom']}>
          <AdditionalInfoMobile
            width={'full-w'}
            isExpandable
            withBackground={false}
            variant={'success'}
          >
            Withdrawal of items is available only within 40 minutes after
            purchase! If you do not have time, the funds will be returned to
            your account!
          </AdditionalInfoMobile>
          <AdditionalInfoMobile
            withBackground={false}
            width={'full-w'}
            isExpandable
            variant={'warn'}
          >
            Purchase of items is temporarily unavailable! Try it after finishing
            the technical work!
          </AdditionalInfoMobile>
        </div>
      )}
    </div>
  );
});
