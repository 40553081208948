'use client';

import { CartConfirmItemDelete } from '@features/desktop/cart/remove/ui';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './remove-item.module.scss';

type RemoveItemFeatureProps = {
  itemId: number;
  className?: string;
};

export const RemoveItemFeatureMobile = observer(
  ({ itemId, className }: RemoveItemFeatureProps) => {
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (itemId) {
        e?.stopPropagation();
      }
    };

    const btnClasses = cn(styles['btn'], className);

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.9 }}
        className={btnClasses}
        variant="tertiary"
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'red-trash-bin-minimalistic'}
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            size={18}
          />
        }
      />
    );
  },
);

export const CartConfirmItemDeleteMobile = ({
  onClick,
  className,
}: {
  onClick: () => void;
  className?: string;
}) => {
  const btnClasses = cn(styles['btn'], className);

  return (
    <Button
      as={motion.button}
      whileTap={{ scale: 0.9 }}
      className={btnClasses}
      variant="tertiary"
      onClick={onClick}
      iconLeft={
        <Illustration
          name={'red-trash-bin-minimalistic'}
          spanTagClassName={styles['icon']}
          spriteName={'icons'}
          size={18}
        />
      }
    />
  );
};
