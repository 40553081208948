import { usePostCartsAddHook } from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { QueryKeys } from '@hooks/queries/queryKeys';
import { useCart } from '@hooks/queries/use-query-cart';
import { useQueryClient } from '@tanstack/react-query';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent, useMemo } from 'react';

import { Product } from '@/shared/types/common';
import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './add-to-cart.module.scss';
type AddToCartShoppingItemFeatureProps = {
  item: Product | null;
  className?: string;
};

export const AddToCartShoppingItemFeatureMobile = observer(
  ({ item, className }: AddToCartShoppingItemFeatureProps) => {
    const { addToCart, items } = useCart();

    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      e?.stopPropagation();
      item &&
        addToCart({
          id: item.id,
          count: 1,
          prices: item.prices!,
          selected: true,
          price: item.price!,
        });
    };

    const btnClasses = cn(styles['btn'], className);

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.9 }}
        className={btnClasses}
        variant="secondary"
        disabled={items.some(cartItem => cartItem.id == item?.id)}
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'cart-plus'}
            id={'add-to-cart'}
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            size={20}
          />
        }
      />
    );
  },
);
