'use client';
import {
  useDeleteCartsSelectedHook,
  useDeleteCartsUnavailableHook,
} from '@api-product/🧺-корзина-🔓/🧺-корзина-🔓';
import { CartItemsResponse } from '@hooks/queries/common.types';
import { QueryKeys } from '@hooks/queries/queryKeys';
import { useCart } from '@hooks/queries/use-query-cart';
import { CartDeleteItemModal } from '@modals';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';

import { Illustration } from '@/shared/ui';
import { Button } from '@/shared/ui/button';

import styles from './cart-clear-soldout.module.scss';

export const CartClearSoldOutFeature = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const { removeAllSoldOut } = useCart();

    // const removeLocalSoldOut = () => {
    //   queryClient.setQueryData(
    //     [QueryKeys.CART],
    //     (prevCart: CartItemsResponse) => {
    //       const updatedList = prevCart.data.list.map(item => {
    //         if (item.count > item.prices.length) {
    //           return { ...item, count: item.prices.length };
    //         }
    //
    //         return item;
    //       });
    //
    //       return {
    //         ...prevCart,
    //         data: {
    //           ...prevCart.data,
    //           list: updatedList,
    //         },
    //       };
    //     },
    //   );
    // };

    return (
      <>
        <Button
          width={'content'}
          className={styles['clear-btn']}
          iconLeft={
            <Illustration
              name={'red-trash-bin-minimalistic'}
              spriteName={'icons'}
            />
          }
          variant={'tertiary'}
          onClick={() => setModalOpened(true)}
          text={'Delete all'}
        />
        <CartDeleteItemModal
          isMobile={isMobile}
          onDelete={removeAllSoldOut}
          isOpened={modalOpened}
          handleClose={() => setModalOpened(false)}
        />
      </>
    );
  },
);
