import { useQueryString } from '@hooks/client';
import { useFavorite } from '@hooks/queries/use-query-favorite';
import cn from 'clsx';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { MouseEvent } from 'react';

import { Button, Illustration } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './add-to-favorite.module.scss';
type AddToCartShoppingItemFeatureProps = {
  realName: string;
  className?: string;
};

export const AddToFavoriteShoppingItemFeatureMobile = observer(
  ({ realName, className }: AddToCartShoppingItemFeatureProps) => {
    const { mappedParams } = useQueryString();
    const { items, addToFavorite, deleteItem } = useFavorite(mappedParams);
    const active = items.find(item => item.realName === realName);
    const handleActionButtonClick = (
      e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    ) => {
      if (realName) {
        e?.stopPropagation();
        !active ? addToFavorite(realName) : deleteItem(realName);
      }
    };

    const btnClasses = cn(
      styles['btn'],
      {
        [styles['liked']]: active,
      },
      className,
    );

    return (
      <Button
        as={motion.button}
        whileTap={{ scale: 0.9 }}
        className={btnClasses}
        variant="secondary"
        onClick={handleActionButtonClick}
        iconLeft={
          <Illustration
            name={'heart'}
            spanTagClassName={styles['icon']}
            spriteName={'icons'}
            size={18}
          />
        }
      />
    );
  },
);
