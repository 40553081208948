'use client';
import { useCart } from '@hooks/queries/use-query-cart';
import cn from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { Badge } from '@/shared/ui';
import { useStore } from '@/store/context';

import styles from './cart-badge-count.module.scss';

export const CartBadgeItemsCounter = observer(
  ({ isMobile }: { isMobile?: boolean }) => {
    const { items } = useCart();

    const count = items.reduce((acc, cur) => cur.count + acc, 0);

    const badgeStyles = cn(
      styles['badge'],
      { [styles['mobile']]: isMobile },
      { [styles['one-number']]: count < 10 },
      { [styles['two-numbers']]: count >= 10 },
      { [styles['three-numbers']]: count > 99 },
    );

    return count > 0 ? (
      <Badge count={count} className={badgeStyles} isMobile={isMobile} />
    ) : null;
  },
);
