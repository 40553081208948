'use client';
import { CardItemOfferEntity } from '@entities/desktop/cards';
import { CardSameItemEntityMobile } from '@entities/mobile/cards';
import { AddToCartOfferFeature } from '@features/desktop/pet/offer/add-to-cart/ui';
import { AddToCartShoppingItemFeatureMobile } from '@features/mobile/add-to-cart';
import { Offer } from '@widgets/desktop/offer/types';
import React, { useState } from 'react';

import { offerItems } from '@/mocks/offer-items';
import { ItemAge, Nullable } from '@/shared/types/common';
import {
  Divider,
  IllustrationModal,
  ModalContent,
  ModalWrapper,
  TagQuantity,
  Typography,
} from '@/shared/ui';
import { BottomSheetUi } from '@/shared/ui/mobile/bottom-sheet';
import { useStore } from '@/store/context';

import styles from './cart-replace-item-modal.module.scss';
export const CartReplaceItemModal = ({
  isMobile,
  isOpened,
  handleClose,
}: {
  isMobile?: boolean;
  isOpened: boolean;
  handleClose: () => void;
}) => {
  return (
    <ModalWrapper
      hideBottomSheet={true}
      className={styles['modal--replace-item']}
      close={() => handleClose()}
      isMobile={isMobile}
      isOpened={isOpened}
    >
      <CartReplaceItemModal.ReplaceItem
        isMobile={isMobile}
        handleClose={() => handleClose()}
      />
    </ModalWrapper>
  );
};

CartReplaceItemModal.ReplaceItem = ({
  isMobile,
  handleClose,
}: {
  isMobile?: boolean;
  handleClose: () => void;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const game = useStore()?.app.getGame;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [items, setItems] = useState(offerItems[game]);

  const renderOfferItem = (item: Offer, index: number) => (
    <React.Fragment key={`cart-offer-${item?.realName}`}>
      <CardItemOfferEntity
        className={styles['item']}
        {...item}
        bottomSlot={<AddToCartOfferFeature item={null} />}
      />
      {index !== items.length - 1 && (
        <Divider style={{ minHeight: 1 }} direction={'horizontal'} />
      )}
    </React.Fragment>
  );

  return isMobile ? (
    <BottomSheetUi
      className={styles['sheet']}
      isOpen={true}
      handleClose={handleClose}
    >
      <BottomSheetUi.Content className={styles['sheet-content']}>
        <div className={styles['items__sheet-header-container']}>
          <BottomSheetUi.Header
            onClick={() =>
              items?.length > 0 ? setItems([]) : setItems(offerItems[game])
            }
          >
            Replace item
          </BottomSheetUi.Header>
          <TagQuantity quantity={items.length} />
        </div>
        <div className={styles['items__sheet-container']}>
          {items.length === 0 && (
            <div className={styles['not-found-container']}>
              <IllustrationModal variant={'same-items-not-found'} />
              <div className={styles['not-found-text-mobile']}>
                <Typography className={styles['title']}>
                  No similar items found
                </Typography>
                <Typography className={styles['subtitle']}>
                  Try selecting a different item or changing filter settings
                </Typography>
              </div>
            </div>
          )}
          {Array.isArray(items) &&
            items.length > 0 &&
            items.map((item, idx) => {
              return (
                <CardSameItemEntityMobile
                  key={`${item.realName}-${idx}-offer-mobile`}
                  leftSlot={
                    <CardSameItemEntityMobile.Item
                      img={item.imageUri}
                      rare={item.rare}
                      game={item.game}
                    />
                  }
                  middleSlot={
                    <CardSameItemEntityMobile.ItemInfo<
                      Nullable<ItemAge> | string
                    >
                      price={{
                        current: item.price ?? 0,
                        old: item.price ?? 0,
                      }}
                      title={item.age ? item.age : item.name}
                      rightSlot={
                        <CardSameItemEntityMobile.ItemProperties
                          flyable={item.flyable}
                          rideable={item.rideable}
                        />
                      }
                    />
                  }
                  rightSlot={
                    <AddToCartShoppingItemFeatureMobile
                      className={styles['sheet__shopping-card-add-to-cart']}
                      item={null}
                    />
                  }
                />
              );
            })}
        </div>
        <BottomSheetUi.Button text={'Cancel'} onClick={handleClose} />
      </BottomSheetUi.Content>
    </BottomSheetUi>
  ) : (
    <ModalContent className={styles['container--replace-item']}>
      <ModalContent.Header
        onClick={() =>
          items?.length > 0 ? setItems([]) : setItems(offerItems[game])
        }
      >
        <div className={styles['header-text']}>
          <ModalContent.HeaderLabel
            className={styles['label--icon']}
            icon={'notes-minimalistic'}
            variant={'primary'}
          >
            Replace
          </ModalContent.HeaderLabel>
          <TagQuantity quantity={items.length} />
        </div>
        <ModalContent.CloseButton onClick={() => handleClose()} />
      </ModalContent.Header>

      <ModalContent.Body className={styles['items-list']}>
        {items.length === 0 ? (
          <div className={styles['not-found']}>
            <IllustrationModal variant={'same-items-not-found'} />
            <div className={styles['not-found-text']}>
              <Typography className={styles['title']}>
                No similar items found
              </Typography>
              <Typography className={styles['subtitle']}>
                Try selecting a different item or changing filter settings
              </Typography>
            </div>
          </div>
        ) : (
          <ul className={styles['items']}>{items.map(renderOfferItem)}</ul>
        )}
      </ModalContent.Body>
      <ModalContent.Footer>
        <ModalContent.Button text={'Cancel'} onClick={handleClose} />
      </ModalContent.Footer>
    </ModalContent>
  );
};
